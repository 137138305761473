<template>
	<div style="height: inherit">
		<section v-if="isAuxiliar">
			<scan-code />
			<!-- <b-button @click="$router.push({name: 'apps-e-commerce-shop'})" size="sm" variant="outline-primary">
				Ir al Ecommerce
			</b-button> -->
			<!-- <e-commerce-shop /> -->
		</section>

		<section v-else id="dashboard-ecommerce">
			
			<b-tabs v-if="isAdministrator || isSeller" class="my-custom-tabs" pills>
				
				<b-tab v-if="isDirector || isAdministrator" title="Dirección" @click="activeTab = 0">
					<template #title>
						<feather-icon icon="AwardIcon" />
						<span>Dirección</span>
					</template>

					<dashboard-administrator v-if="activeTab == 0" />
				</b-tab>

				<b-tab title="Cobranza" @click="activeTab = 1">
					<template #title>
						<feather-icon icon="ShoppingBagIcon" />
						<span>Cobranza</span>
					</template>
					
					<dashboard-charges v-if="activeTab == 1" />
					
				</b-tab>

				<b-tab title="Ventas" @click="activeTab = 2">
					<template #title>
						<feather-icon icon="ShoppingCartIcon" />
						<span>Ventas</span>
					</template>	

					<b-card no-body>
						<b-row class="m-1">
							<b-col cols="12" md="4">
								<b-form-group label="Vendedor" label-for="vendedores">
									<v-select
										:disabled="!isAdministrator"
										label="nombre"
										id="vendedores"
										v-model="vendedor_Id"
										:options="sellers"
										:reduce="option => option.vendedor_Id"
										class="select-size-sm w-100"
										placeholder="Selecciona un vendedor"
										:clearable="false" :searchable="true"
										@input="selectedSeller"
									/>
								</b-form-group>
							</b-col>
							<b-col cols="12" md="4">
								<b-form-group label="Periodo" label-for="filter_dates">
									<v-select
										id="filter_dates"
										label="label"
										v-model="filterDate"
										:options="filterOptions"
										:reduce="option => option.key"
										class="select-size-sm text-capitalize"
										placeholder="Selecciona una opción"
										:clearable="false" :searchable="false"
										@input="selectedFilterDates"
									/>	
								</b-form-group>
							</b-col>
							<b-col cols="12" md="4" class="mt-2">
								<b-button @click="refreshDashboard" size="sm" variant="outline-primary">
									<feather-icon icon="RefreshCwIcon" />
									Actualizar
								</b-button>
							</b-col>
						</b-row>
					</b-card>
					
					<dashboard-seller v-if="activeTab == 2 && (isAdministrator || isSeller)" :refresh="isRefreshDashboard" />
				</b-tab>

				<b-tab title="Rutas" @click="activeTab = 3">
					<template #title>
						<feather-icon icon="TruckIcon" />
						<span>Rutas</span>
					</template>

					<dashboard-routings v-if="activeTab == 3"/>
				</b-tab>

				<b-tab title="Rutas" @click="activeTab = 4">
					<template #title>
						<feather-icon icon="ArchiveIcon" />
						<span>Bitácoras</span>
					</template>

					<b-row class="match-height">
						<b-col xl="8" md="6">
							<global-binnacles v-if="activeTab == 4"/>
						</b-col>
						
					</b-row>

					
				</b-tab>

			</b-tabs>

			<b-row v-else>
				<b-col cols="12">
					<b-card title="Welcome to Ferreysa">
						<b-card-text>
							Ferreysa is a web application that allows you to manage the delivery routes of your company.
						</b-card-text>
					</b-card>
				</b-col>
			</b-row>

			
			
			<!-- <b-row class="match-height">
				<b-col cols="12" v-if="isAdministrator">				
					<b-tabs v-model="activeTab" class="my-custom-tabs" pills>

						<b-tab active title="Gerencia" @click="activeTab = 0">
							<template #title>
								<feather-icon icon="AwardIcon" />
								<span>Gerencia</span>
							</template>	

							<dashboard-administrator v-if="activeTab == 0" />
						</b-tab>

						<b-tab title="Ventas" @click="activeTab = 1">
							<template #title>
								<feather-icon icon="ShoppingCartIcon" />
								<span>Ventas</span>
							</template>	

							<b-card no-body>
								<b-row class="m-1">
									<b-col cols="12" md="4">
										<b-form-group label="Vendedor" label-for="vendedores">
											<v-select
												label="nombre"
												id="vendedores"
												v-model="vendedor_Id"
												:options="sellers"
												:reduce="option => option.vendedor_Id"
												class="select-size-sm w-100"
												placeholder="Selecciona un vendedor"
												:clearable="false" :searchable="true"
												@input="selectedSeller"
											/>
										</b-form-group>
									</b-col>
									<b-col cols="12" md="4">
										<b-form-group label="Periodo" label-for="filter_dates">
											<v-select
												id="filter_dates"
												label="label"
												v-model="filterDate"
												:options="filterOptions"
												:reduce="option => option.key"
												class="select-size-sm text-capitalize"
												placeholder="Selecciona una opción"
												:clearable="false" :searchable="false"
												@input="selectedFilterDates"
											/>	
										</b-form-group>
									</b-col>
									<b-col cols="12" md="4" class="mt-2">
										<b-button @click="refreshDashboard" size="sm" variant="outline-primary">
											<feather-icon icon="RefreshCwIcon" />
											Actualizar
										</b-button>
									</b-col>
								</b-row>
							</b-card>
							
							<dashboard-seller v-if="activeTab == 1" :refresh="isRefreshDashboard" />
						</b-tab>
						<b-tab title="Rutas" @click="activeTab = 2">
							<template #title>
								<feather-icon icon="TruckIcon" />
								<span>Rutas</span>
							</template>

							<dashboard-routings v-if="activeTab == 2"/>
						</b-tab>
					</b-tabs>
				</b-col>
				<b-col cols="12" v-else-if="isSeller">
					<dashboard-seller :refresh="isRefreshDashboardSeller"/>
				</b-col>
				<b-col cols="12" v-else>
					<b-card title="Welcome to Ferreysa">
						<b-card-text>
							Ferreysa is a web application that allows you to manage the delivery routes of your company.
						</b-card-text>
					</b-card>
				</b-col>
			</b-row> -->
		</section>
	</div>
</template>

<script>
import { BCard, BCardHeader, BCardText, BLink, BImg, BRow, BCol, BTabs, BTab, BNavItem, BFormGroup, BButton, } from 'bootstrap-vue'
import { ref, onMounted, computed, watch, reactive, defineEmits, onErrorCaptured, nextTick } from "@vue/composition-api";
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import vSelect from 'vue-select'
import moment from 'moment';
import DashboardAdministrator from './DashboardAdministrator.vue'
import DashboardSeller from './DashboardSeller.vue'
import DashboardRoutings from './DashboardRoutings.vue';
import DashboardCharges from './DashboardCharges.vue';
import DeliveryRoutesProvider from '@/providers/DeliveryRoutes'
import ScanCode from '@/@core/components/articles/ScanCode.vue'
import ECommerceShop from '@/views/pages/e-commerce/e-commerce-shop/ECommerceShop.vue'
const DeliveryRoutesResource = new DeliveryRoutesProvider()
import SellersProvider from '@/providers/Sellers'
import GlobalBinnacles from '@/@core/components/GlobalBinnacles.vue';

const SellersResource = new SellersProvider()

export default {
	components: {
		BCard,
		BCardHeader,
		BCardText,
		BLink,
		BImg,
		BRow,
		BCol,
		BTabs, 
		BTab,
		BNavItem,
		BFormGroup,
		BButton,

		//
		vSelect,

		//
		ScanCode,
		DashboardAdministrator,
		DashboardSeller,
		DashboardRoutings,
		DashboardCharges,
		GlobalBinnacles,
		GlobalBinnacles,
		ECommerceShop,	
	},
	data() {
		return {
			title: 'Home',
			logo: require('@/assets/images/logo/ferreysa.png')
		}
	},
	computed: {
		isSeller() {
			return store.getters['auth/getUser'] ? store.getters['auth/getUser'].customRol.id == store.getters['auth/getIsRoleSeller'] : false
		},
		isAdministrator() {
			return store.getters['auth/getUser'] ? store.getters['auth/getUser'].customRol.id == store.getters['auth/getAdministratorRol'] : false
		},
		isDirector() {
			return store.getters['auth/getUser'] ? store.getters['auth/getUser'].customRol.id == store.getters['auth/getDirectorRol'] : false
		},
		isAuxiliar() {
			return store.getters['auth/getUser'] ? store.getters['auth/getUser'].customRol.id == store.getters['auth/getAuxiliarRol'] : false
		}
	},
	setup() {
		const sellers = ref([])
		const searchQuery = ref('')
		const activeTab = ref(0)
		const showDashboardAdministrator = ref(false)
		const isRefreshDashboard = ref(false)

		// Obtener la fecha actual
		const currentDate = moment();

		// Mes actual
		const currentMonth = currentDate.format('MMMM'); // Nombre del mes actual
		const currentYear = currentDate.format('YYYY'); // Año en curso

		// Mes anterior
		const previousMonthDate = currentDate.clone().subtract(1, 'month');
		const previousMonth = previousMonthDate.format('MMMM'); // Nombre del mes anterior
		const previousMonthYear = previousMonthDate.format('YYYY'); // Año del mes anterior

		// Mes actual del año anterior
		const currentMonthLastYear = currentDate.clone().subtract(1, 'year');
		const currentMonthLastYearName = currentMonthLastYear.format('MMMM'); // Mes actual, pero del año pasado
		const lastYear = currentMonthLastYear.format('YYYY'); // Año anterior

		// Mes anterior del año pasado
		const previousMonthLastYear = previousMonthDate.clone().subtract(1, 'year');
		const previousMonthLastYearName = previousMonthLastYear.format('MMMM'); // Mes anterior, pero del año pasado
		const previousYear = previousMonthLastYear.format('YYYY'); // Año anterior para el mes anterior

		const vendedor_Id = ref('')
		const filterDate = ref('')

		const filterOptions = ref([
			{ key: 'current_month', label: `${currentMonth} ${currentYear}`, vendedor_Id: null },
			{ key: 'previous_month', label: `${previousMonth} ${currentYear}`, vendedor_Id: null },
			{ key: 'current_month_last_year', label: `${currentMonthLastYearName} ${lastYear}`, vendedor_Id: null },
			{ key: 'previous_month_last_year', label: `${previousMonthLastYearName} ${previousYear}`, vendedor_Id: null },
		])


		const filterSellers = computed(() => {
			return sellers.value.filter(seller =>
				seller.nombre.toLowerCase().includes(searchQuery.value.toLowerCase())
			);
		})

		const filterBy = computed(() => {
			return store.getters['auth/getFilterOnDashboardSeller']
		})

		const isSeller = computed(() => {
			return store.getters['auth/getUser'] ? store.getters['auth/getUser'].customRol.id == store.getters['auth/getIsRoleSeller'] : false
		})

		const isViewHomeAndIsSeller = computed(() => {
			return store.getters['auth/getCurrentRoute'] === 'home' &&
			( (store.getters['auth/getUser'] ? store.getters['auth/getUser'].customRol.id == store.getters['auth/getIsRoleSeller'] : false) ||
			(store.getters['auth/getUser'] ? store.getters['auth/getUser'].customRol.id == store.getters['auth/getAdministratorRol'] : false))
		})	

		const isRefreshDashboardSeller = computed(() => {
			return store.getters['auth/getIsRefreshDashboard']
		})

		const changeFilterOnDashboardSeller = (option) => {
			store.dispatch('auth/UPDATE_FILTER_ON_DASHBOARD_SELLER', option)
		}

		const selectedFilterDates = (evt) => {
			const option = filterOptions.value.find(o => o.key === evt)
			store.dispatch('auth/UPDATE_FILTER_ON_DASHBOARD_SELLER', option)
		}

		const selectedSeller = (evt) => {			
			store.dispatch('auth/UPDATE_SELLER_ID', evt)
		}

		const getSellers = async () => {			
			const checkSellers = store.getters['auth/getSellers']
			if (checkSellers.length <= 0) {
				const { data } = await SellersResource.getAll()
				sellers.value = data.data
				store.dispatch('auth/UPDATE_SELLERS', sellers.value)
			} else {
				sellers.value = checkSellers
			}
		}

		const refreshDashboard = () => {
			// Cambiar a true, luego inmediatamente a false para forzar reactividad
			isRefreshDashboard.value = false;
			nextTick(() => {
				isRefreshDashboard.value = true;
			});
		}

		onMounted( async () => {
			await getSellers()
			
			//Filter dates
			const filterOnDashboardSeller = store.getters['auth/getFilterOnDashboardSeller']
			if(!filterOnDashboardSeller || filterOnDashboardSeller == undefined || !filterOnDashboardSeller.key) {
				store.dispatch('auth/UPDATE_FILTER_ON_DASHBOARD_SELLER', filterOptions.value[0])
				filterDate.value = filterOptions.value[0].key
			} else {
				filterDate.value = filterOnDashboardSeller.key
			}

			//Filter seller id
			vendedor_Id.value = store.getters['auth/getSellerId'] || sellers.value[0].vendedor_Id
			store.dispatch('auth/UPDATE_SELLER_ID', vendedor_Id.value)			
		})

		watch(activeTab, (tab) => {
			showDashboardAdministrator.value = tab == 1 ? true : false
		})

		return {
			sellers,
			searchQuery,
			activeTab,
			showDashboardAdministrator,

			filterBy,
			filterOptions,
			vendedor_Id,
			filterDate,
			isRefreshDashboard,
			isRefreshDashboardSeller,
			

			//
			filterSellers,
			selectedFilterDates,
			refreshDashboard,
			isViewHomeAndIsSeller,			
			selectedSeller,
			changeFilterOnDashboardSeller,
		}
	}
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
</style>